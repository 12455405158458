import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Błąd 404 - Strony nie znaleziono"/>
      <div class="container pt-70 pb-70">
          <div class="row">
              <div className="col-md-12">
                <h1 className="text-center">Błąd 404 - Strony nie znaleziono</h1>
                <p className="text-center">
                    Przepraszamy{" "}
                    <span role="img" aria-label="Pensive emoji">
                    😔
                    </span>{" "}
                    nie znaleźliśmy strony której szukasz.
                    <a href="/"> Wróć na stronę główną</a>
                </p>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
